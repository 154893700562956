// extracted by mini-css-extract-plugin
export var lbContainerOuter = "C_vn";
export var lbContainerInner = "C_vp";
export var movingForwards = "C_vq";
export var movingForwardsOther = "C_vr";
export var movingBackwards = "C_vs";
export var movingBackwardsOther = "C_vt";
export var lbImage = "C_vv";
export var lbOtherImage = "C_vw";
export var prevButton = "C_vx";
export var nextButton = "C_vy";
export var closing = "C_vz";
export var appear = "C_vB";